import React, { useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField'
import { useQuery, gql } from '@apollo/client'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { PriceInput, Dollars } from 'tools'
import { UserContext } from 'UserStore'
import { GlobalContext } from 'GlobalStore'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import { useChannel, useEventHandler } from '@ericlathrop/phoenix-js-react-hooks'
import { RefetchRegistry } from 'RefetchRegistry'
import { UpdateRefetchRegistry } from 'UpdateRefetchRegistry'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const QUERY = gql`
  query manualCompensations($quoteId: ID!) {
    manualCompensations(quoteId: $quoteId) {
      id
      technicianId
      userId
      createdAt
      amount
      payoutId
      note
      deletedAt
    }
  }
`

const ManualCompensations = ({ quoteId }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const [manualCompTechnicianId, setManualCompTechnicianId] = useState(null)
  const [manualCompAmount, setManualCompAmount] = useState(0)
  const [manualCompNote, setManualCompNote] = useState('')
  const [registerRefetch, unregisterRefetch] = useContext(RefetchRegistry)
  // const [registerUpdateRefetch, unregisterUpdateRefetch] = useContext(UpdateRefetchRegistry)

  const allowCreate = manualCompTechnicianId && Number(manualCompAmount) > 0 && manualCompNote.length > 2

  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: { quoteId },
  })

  useEffect(() => {
    const key = registerRefetch({ types: ['ManualCompensation'], refetch })
    return () => unregisterRefetch(key)
  }, [])

  // useEffect(() => {
  //   const key = registerUpdateRefetch({ types: ['ManualCompensation'], refetch })
  //   return () => unregisterUpdateRefetch(key)
  // }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const technicianById = id => global.technicians.find(tech => tech.id === id)

  const handleChangeNote = e => setManualCompNote(e.target.value)

  const handleClickCreateManualCompensation = () =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/create_manual_compensation`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        quote_id: quoteId,
        technician_id: manualCompTechnicianId,
        amount: Number(manualCompAmount),
        note: manualCompNote,
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        setManualCompTechnicianId(null)
        setManualCompAmount(0)
        setManualCompNote('')
      })
      .catch(err => {
        window.alert(err)
      })

  const deleteManualCompensation = id =>
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_manual_compensation`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        manual_compensation_id: id,
      }),
    }).then(res => res.ok || alert('Error'))

  return (
    <div style={{ display: 'inline-block', border: '1px solid gray', padding: '1em' }}>
      <b style={{ color: 'red' }}>🔥Manual Compensations🔥</b>

      <br />

      <InputLabel>Technician</InputLabel>
      <Select
        style={{ width: 120 }}
        label='Technician'
        name='manualCompensationTechnician'
        value={manualCompTechnicianId || ''}
        onChange={event => setManualCompTechnicianId(event.target.value)}
      >
        {[...global.technicians]
          .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
          .map(tech => (
            <MenuItem key={tech.id} value={tech.id}>
              {tech.firstName} {tech.lastName}
            </MenuItem>
          ))}
      </Select>
      <TextField
        style={{ width: 120 }}
        value={manualCompAmount}
        onChange={event => setManualCompAmount(Number(event.target.value))}
        name='manualCompensationAmount'
        InputProps={{
          inputComponent: PriceInput,
        }}
      />
      <TextField
        name='manualCompensationNote'
        value={manualCompNote}
        label='Note'
        variant='outlined'
        multiline
        onChange={handleChangeNote}
      />

      <Button
        cy-data='create-manual-compensation-amount-button'
        size='small'
        disabled={!allowCreate}
        variant='outlined'
        onClick={handleClickCreateManualCompensation}
      >
        Create
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead />
          <TableBody>
            {data.manualCompensations
              .filter(comp => !comp.deletedAt)
              .map(comp => (
                <TableRow key={comp.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='right'>
                    {technicianById(comp.technicianId).firstName} {technicianById(comp.technicianId).lastName}
                  </TableCell>
                  <TableCell align='right'>
                    <Dollars value={comp.amount} />
                  </TableCell>
                  <TableCell align='right'>"{comp.note}"</TableCell>
                  <TableCell align='right'>
                    {comp.payoutId ? (
                      'paid out'
                    ) : (
                      <>
                        <b style={{ color: 'red' }}>not yet paid out</b>
                        <Button
                          cy-data='delete-manual-compensation'
                          onClick={() => deleteManualCompensation(comp.id)}
                          size='small'
                        >
                          delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ManualCompensations
