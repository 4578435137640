import { createContext, useMemo, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { UserContext } from 'UserStore'
import { logout } from 'auth'

export const GlobalContext = createContext()

const USERS_QUERY = gql`
  query users {
    users {
      id
      firstName
      lastName
      twilioIdentity
      status
      roles
    }
  }
`

const TECHNICIAN_QUERY = gql`
  query technicians {
    technicians {
      id
      name
      firstName
      lastName
      market
      displayMarket
      active
      state
      offdays
      isBToB
      isEmployee
      availability
      note
    }
  }
`

const GlobalStore = ({ children }) => {
  const [user, userReady] = useContext(UserContext)
  const { data: usersData, error: usersError } = useQuery(USERS_QUERY)
  const { data: techsData, error: techsError } = useQuery(TECHNICIAN_QUERY)

  const global = useMemo(
    () => ({ users: usersData?.users || [], technicians: techsData?.technicians || [] }),
    [usersData, techsData]
  )

  const ready = useMemo(
    () => userReady && global.users.length > 0 && global.technicians.length > 0,
    [userReady, global]
  )

  // auto logout on useQuery error (invalid token)
  useEffect(() => (usersError || techsError) && logout(), [usersError, techsError])
  // auto logout on user.status !== 'active'
  useEffect(() => {
    if (!ready) return
    if (global.users.find(u => u.id === user.id)?.status != 'active') logout()
  }, [ready])

  if (!ready) return <></>

  return <GlobalContext.Provider value={[global, ready]}>{children}</GlobalContext.Provider>
}

export default GlobalStore
