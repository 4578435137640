import { useEffect, useState, useContext, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Notes from 'components/Notes'
import DescendentNotes from 'components/DescendentNotes'
import TechNotes from 'components/TechNotes'
import Breadcrumbs from 'components/Breadcrumbs'
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Quotes from './components/Quotes'
import MuiAlert from '@mui/material/Alert'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  cdnUrl,
  TLD,
  thumbnailUrlForPhoto,
  fullSizeUrlForPhoto,
  formatPhotoTimestamp,
  getPresignedUploadParams,
  fileExtensionPattern,
} from 'tools'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import StreamDump from 'components/StreamDump'
import {
  LaunchRounded,
  PublishedWithChangesOutlined,
  ThumbDownRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material'
import localForage from 'localforage'
import { UserContext } from 'UserStore'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import { StatusBanner } from 'components/StatusBanner/StatusBanner'

const useStyles = makeStyles(theme => ({
  grid_root: {
    flexGrow: 1,
  },
  thumbnail_paper: {
    color: theme.palette.text.secondary,
    padding: '8px 8px 4px 8px',
    display: 'inline-flex',
    marginRight: '.75rem',
    marginBottom: '.75rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
  thumbnail_paper_container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  thumbnail_image: {
    width: '100%',
    minWidth: '120px',
    maxHeight: '120px',
    minHeight: '120px',
    objectFit: 'cover',
  },
  grid_section: {
    marginTop: '1em',
  },
  modal_close: {
    top: '0.5em',
    right: '0.5em',
    position: 'absolute',
    fontSize: '1.75em',
    color: 'red',
    border: '1px solid red',
    padding: '0 0.3em',
    borderRadius: '7px',
    zIndex: '10',
    textShadow: '1px 1px 1px #00000045',
    boxShadow: '1px 1px 1px #00000045',
    cursor: 'pointer',
  },
  modal_paper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  chipLabel: {
    padding: '0 .25rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chip: {
    position: 'absolute',
    padding: '0rem',
    height: '1.25rem',
    paddingTop: '.15rem',
    borderRadius: '4px',
    background: 'rgba(0, 0, 0, 0.5)',
    marginLeft: '.25rem',
    marginTop: '.25rem',
    backdropFilter: 'blur(6px)',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(0, 0, 0, 0.75)',
      '& p': {
        color: '#fff',
        display: 'block',
      },
    },
    '& svg': {
      maxWidth: '1rem',
      display: 'block',
    },
    '& p': {
      display: 'none',
      fontWeight: 700,
      marginLeft: '.25rem',
    },
  },
}))

const toggleButtonStyles = {
  float: 'right',
  minWidth: '30px',
  width: '30px',
  minHeight: '20px',
  padding: '.125rem',
  backdropFilter: 'blur(6px)',
  background: '#11111180',
  '& svg': {
    fontSize: '18px',
    ml: '0rem',
    transition: 'all .25s ease-in-out',
    pointerEvents: 'none',
  },
  '& span': {
    fontSize: '12px',
    maxWidth: '0px !important',
    overflow: 'hidden',
    transition: 'all .25s ease-in-out',
  },
}

const LeadShow = ({ lead }) => {
  const classes = useStyles()
  const [user] = useContext(UserContext)
  const fileInput = useRef()
  const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false)
  const [modalPhoto, setModalPhoto] = useState('')
  const [photosToUpload, setPhotosToUpload] = useState([])
  const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Lead', targetId: lead.id })
    return () => unregisterTaskTarget(key)
  }, [])

  const modalPhotoIndex = currentPhoto => lead.photos.findIndex(photo => photo.id === currentPhoto.id)

  const stepPhotoBack = () => setModalPhoto(prev => lead.photos[Math.max(modalPhotoIndex(prev) - 1, 0)])

  const stepPhotoForward = () =>
    setModalPhoto(prev => lead.photos[Math.min(modalPhotoIndex(prev) + 1, lead.photos.length - 1)])

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 37:
        // left
        stepPhotoBack()
        break
      case 38:
        // up
        break
      case 39:
        // right
        stepPhotoForward()
        break
      case 40:
        // down
        break
      default:
        break
    }
  }

  useEffect(() => {
    const listener = window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', listener)
  }, [])

  const handleClosePhotoModal = () => {
    setPhotoModalIsOpen(false)
  }
  const handleClickPhotoThumbnail = photo => {
    setModalPhoto(photo)
    setPhotoModalIsOpen(true)
  }

  const handleShowPreview = () => {
    const files = Object.values(fileInput.current.files).map(file => ({
      file,
      url: URL.createObjectURL(file),
      uuid: uuid(),
    }))

    setPhotosToUpload(files)
  }

  const handleUploadPhotos = () => {
    Promise.all(
      photosToUpload.map(async ({ file, uuid }) => {
        try {
          getPresignedUploadParams(`${uuid}-${Date.now()}${file.name.match(fileExtensionPattern)[0]}`)
            .then(async res => {
              const { uploadUrl } = res
              await axios({
                method: 'put',
                url: uploadUrl,
                headers: { 'Content-Type': file.type },
                data: file,
              })
              return res
            })
            .then(res => {
              axios({
                method: 'POST',
                url: `${process.env.REACT_APP_COMMAND_ROOT}/api/add_photo_url_to_lead`,
                data: {
                  lead_id: lead.id,
                  photo_url: res.fileUrl,
                },
                auth: {
                  username: process.env.REACT_APP_API_USERNAME,
                  password: process.env.REACT_APP_API_PASSWORD,
                },
              })
            })
        } catch (err) {
          console.error(err.message)
          alert('Error')
        }
      })
    ).finally(() => {
      setPhotosToUpload([])
    })
  }

  const handleHidePhoto = (photoId, hiddenAt) => {
    if (window.confirm('Are you sure you want to hide or unhide this photo from the customer?')) {
      localForage.getItem('user').then(res => {
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/api/${hiddenAt ? 'unhide_photo_on_lead' : 'hide_photo_on_lead'}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(
              process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD
            )}`,
          },
          body: JSON.stringify({
            photo_id: photoId,
            lead_id: lead.id,
            user_id: res.id,
          }),
        }).then(response => {
          if (response.ok) {
            return true
          } else {
            alert('Error')
          }
        })
      })
    }
  }

  useEffect(() => {
    if (!successMessage) {
      return false
    }
    setSuccessSnackbarIsOpen(true)
  }, [successMessage])

  const photoUploaderUrl = `https://www.carbodylab.${TLD}/leads/${lead.id}/photo-uploader`

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Breadcrumbs lead={lead} activeObject={lead} />
              <Quotes lead={lead} />
            </Grid>

            <Grid item xs={12}>
              <Button variant='contained' size='small' endIcon={<AddAPhotoIcon />} component='label'>
                Upload photo
                <input
                  hidden
                  multiple
                  ref={fileInput}
                  accept='image/jpg,image/jpeg,image/png'
                  type='file'
                  onChange={handleShowPreview}
                />
              </Button>
              {lead?.serviceType === 'autobody' && user.roles.includes('admin') && (
                <Button
                  disabled={lead?.aiEstimateStatus === 'requested'}
                  onClick={() =>
                    window.confirm('Are you sure?') &&
                    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/submit_photos_to_inspekt_labs`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        leadId: lead.id,
                        userId: user.id,
                      }),
                    })
                  }
                  variant='contained'
                  size='small'
                  endIcon={<LaunchRounded />}
                  component='label'
                  sx={{ ml: '1rem' }}
                >
                  Submit to inspektlabs
                </Button>
              )}

              {lead?.aiEstimateStatus === 'requested' && (
                <StatusBanner
                  icon={PublishedWithChangesOutlined}
                  text='Photos submitted to inspektlabs on'
                  timestamp={lead?.aiEstimateRequestedAt}
                />
              )}
              {lead?.aiEstimateStatus === 'rejected' && (
                <StatusBanner
                  icon={ThumbDownRounded}
                  text='Inspektlabs rejected on'
                  timestamp={lead?.aiEstimateRejectedAt}
                />
              )}
              {lead?.aiEstimateStatus === 'completed' && (
                <StatusBanner text='Inspektlabs completed estimate on' timestamp={lead?.aiEstimateReceivedAt} />
              )}

              <Dialog
                open={photosToUpload.length > 0}
                onClose={() => {
                  setPhotosToUpload([])
                }}
                PaperProps={{
                  style: {
                    maxWidth: '1080px',
                    borderRadius: '12px',
                  },
                }}
              >
                <DialogTitle id='alert-dialog-title'>Selected photo{photosToUpload.length > 1 && 's'}</DialogTitle>
                <DialogContent>
                  <Grid gap={4} container>
                    {photosToUpload.map(({ url, uuid }) => (
                      <Grid
                        item
                        key={uuid}
                        alt={uuid}
                        xs={3}
                        sx={{
                          backgroundImage: `url(${url})`,
                          backgroundSize: 'cover',
                          borderRadius: '8px',
                          minWidth: '300px',
                          minHeight: '300px',
                          '&::before': {
                            display: 'block',
                            content: "''",
                            paddingBottom: '100%',
                          },
                        }}
                      />
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ marginX: '2rem', marginBottom: '1rem' }}>
                  <Button variant='contained' onClick={handleUploadPhotos} autoFocus>
                    Upload
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid item xs={12}>
              {lead.photos.map(photo => (
                <Paper key={photo.id} className={classes.thumbnail_paper}>
                  <div className={classes.thumbnail_paper_container}>
                    <Button
                      disableElevation
                      disableRipple
                      variant='contained'
                      onClick={() => handleHidePhoto(photo.id, photo.hiddenAt)}
                      sx={{
                        ...toggleButtonStyles,
                        marginBottom: '-25px',
                        marginLeft: '.25rem',
                      }}
                    >
                      {photo.hiddenAt ? <VisibilityOffRounded /> : <VisibilityRounded />}
                    </Button>
                    <img
                      alt='alt'
                      className={classes.thumbnail_image}
                      onClick={() => handleClickPhotoThumbnail(photo)}
                      src={thumbnailUrlForPhoto(photo)}
                    />

                    <div style={{ marginTop: '4px' }}>{formatPhotoTimestamp(photo.timestamp)}</div>
                  </div>
                </Paper>
              ))}
            </Grid>

            <Grid item xs={12}>
              <CopyToClipboard
                style={{ cursor: 'pointer' }}
                onCopy={() => setSuccessMessage('Copied!')}
                text={photoUploaderUrl}
              >
                <span>
                  Photo uploader url: (click to copy)
                  <br />
                  {photoUploaderUrl}
                </span>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Notes objectType='lead' objectId={lead.id} />
              <DescendentNotes objectType='lead' objectId={lead.id} />
            </Grid>
            <Grid item xs={6}>
              <TechNotes parentType='lead' parentId={lead.id} leadId={lead.id} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StreamDump stream={`Lead$${lead.id}`} />
        </Grid>
      </Grid>

      {lead.photos?.length > 0 && (
        <Modal open={photoModalIsOpen} onClose={handleClosePhotoModal}>
          <div className={classes.modal_paper}>
            <div onClick={() => setPhotoModalIsOpen(false)} className={classes.modal_close}>
              X
            </div>

            <PinchZoomPan position='center' maxScale={2}>
              <img alt='default_photo' src={cdnUrl(modalPhoto ? fullSizeUrlForPhoto(modalPhoto) : '')} />
            </PinchZoomPan>

            {modalPhoto.id !== lead.photos[0].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoBack}
              >
                ◄
              </div>
            )}
            {modalPhoto.id !== lead.photos[lead.photos.length - 1].id && (
              <div
                style={{
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  zIndex: 100,
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  padding: '0.5em',
                  fontSize: '1.5em',
                  cursor: 'pointer',
                }}
                onClick={stepPhotoForward}
              >
                ►
              </div>
            )}
          </div>
        </Modal>
      )}

      <Snackbar
        open={successSnackbarIsOpen}
        autoHideDuration={2000}
        color='primary'
        onClose={() => setSuccessSnackbarIsOpen(false)}
      >
        <MuiAlert severity='success'>{successMessage}</MuiAlert>
      </Snackbar>
    </>
  )
}

export default LeadShow
