import { useEffect, useState, useContext } from 'react'
import { useParams, Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useQuery, gql } from '@apollo/client'
import { GlobalContext } from 'GlobalStore'
import { UserContext } from 'UserStore'
import { CreateATask } from 'Tasks'
import {
  CardContent, Grid, Typography, Card, Box, Snackbar, Checkbox, FormControlLabel, Dialog,
  FormControl, InputLabel, Select, MenuItem, Button, Modal, TextField, Chip, Switch as MuiSwitch
} from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import BusinessIcon from '@mui/icons-material/Business'
import ApartmentIcon from '@mui/icons-material/Apartment'
import HomeIcon from '@mui/icons-material/Home'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import Show from './Show'
import Edit from './Edit'
import AvailabilityMap from './AvailabilityMap'
import { makeStyles } from '@mui/styles'
import QuotesRouter from './Quotes/Router'
import useBearerTokenHeaders from 'hooks/useBearerTokenHeaders'
import { keysToCamel } from 'tools'
import { TaskTargetRegistry } from 'TaskTargetRegistry'
import LeadSiblingWarning from './components/LeadSiblingWarning'
import leadLabels from 'data/leadLabels'
import { GppBadRounded, StorageOutlined, TagOutlined, VerifiedUserRounded } from '@mui/icons-material'
import LeadDuplicatesWarning from './components/LeadDuplicatesWarning'
import { DuplicateLead } from './components/DuplicateLead'
import LabelReadWriter from '../components/LabelReadWriter'

import appleData from '@emoji-mart/data/sets/14/apple.json'
import { init } from 'emoji-mart'
import Picker from '@emoji-mart/react'
import { OmegaVehicleInfo } from './components/OmegaVehicleInfo'

init({ appleData, set: 'apple' })

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const GET_LEAD_DATA = gql`
  query lead($id: ID!) {
    lead(id: $id) {
      id
      name
      email
      phone
      addressLineOne
      addressLineTwo
      city
      state
      zip
      make
      model
      tireDataStringJson
      year
      description
      contextNotes
      photos
      createdAt
      deletedAt
      repairLocation
      zip
      source
      omegaVehicleId
      market
      makeShortName
      modelShortName
      vehicle
      noWay
      carparts
      soldAt
      soldByUserId
      isATest
      garageConfirmed
      customerDeclinedAt
      customerDeclinedReason
      phoneLead
      hot
      label
      serviceType
      vin
      addressVerifiedAt
      propertyManagerApprovedAt
      morePhotosRequestedAt
      panelSpecifyRequestedAt
      vinRequestedAt
      markedWaitingForCustomerAt
      addressRequestedAt
      appointmentOfferedAt
      quoteSentAt
      interactionStatuses
      useTechnicianDriveway
      emojis
      serviceUpReferral
      aiEstimateStatus
      aiEstimateRequestedAt
      aiEstimateRejectedAt
      aiEstimateReceivedAt
      vehicleIsAluminum
      serviceType
      selectedGlassLocations
      markedGravyAt
      originalLead {
        id
        name
      }
      duplicatedLeads {
        id
        name
        email
        phone
        createdAt
        deletedAt
      }
      customer {
        id
        firstName
        lastName
        phone
        noClose
      }
    }
  }
`

const leadStyles = makeStyles(theme => ({
  leadAttr: {
    marginRight: '15px',
    display: 'inline-block',
    '& .verified': {
      color: 'green',
      '& svg': {
        color: 'green',
      },
    },
    '& .unverified': {
      color: 'red',
      '& svg': {
        color: 'red',
      },
    },
    '& svg': {
      fontSize: '18px',
    },
    '& span': {
      fontWeight: 'bold',
    },
    '& .MuiChip-root': {
      marginLeft: '.25rem',
    },
  },
  wrapDeleted: { backgroundColor: '#f8f8f8' },
  wrapNormal: { backgroundColor: 'aliceblue' },
}))

const emojiButtonStyles = makeStyles(theme => ({
  emojiSelector: {
    position: 'relative',
    width: 'fit-content',
    display: 'inline-block',
    '& .emoji-list': {
      position: 'absolute',
    },
    '& .add-emoji-img': {
      height: '20px',
      width: '20px',
    },
  },
}))

const LeadRoot = ({ customer }) => {
  const bearerTokenHeaders = useBearerTokenHeaders()
  const [user] = useContext(UserContext)
  const [global] = useContext(GlobalContext)
  const classes = leadStyles()
  const emojiClasses = emojiButtonStyles()
  const { path } = useRouteMatch()
  const { leadId } = useParams()
  const [draftedMorePicsOnClose, setDraftedMorePicsOnClose] = useState(false)
  const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const { registerTaskTarget, unregisterTaskTarget } = useContext(TaskTargetRegistry)
  const [showCustomerDeclinedModal, setShowCustomerDeclinedModal] = useState(false)
  const [customerDeclinedReason, setCustomerDeclinedReason] = useState('')
  const [showEditContextNote, setShowEditContextNote] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [editableLead, setEditableLead] = useState({ contextNotes: '' })
  const [showEmojis, setShowEmojis] = useState(false)

  // const addEmoji= (emoji) =>{
  //   setEmojis(prevEmojis => [...prevEmojis, emoji]);
  //   console.log(emojis)
  // }

  const handlePickerClose = () => {
    handleUpdateEmojis(emojis)
    setShowEmojis(false)
  }

  const { loading, error, data, refetch } = useQuery(GET_LEAD_DATA, {
    // notifyOnNetworkStatusChange: true,
    variables: { id: leadId },
  })

  const [emojis, setEmojis] = useState([])

  useEffect(() => {
    setEditableLead({ contextNotes: data?.lead?.contextNotes || '' })
  }, [data])

  useEffect(() => {
    if (data?.lead) {
      setEmojis(data?.lead?.emojis)
    }
  }, [data])

  useEffect(() => {
    const key = registerTaskTarget({ targetType: 'Lead', targetId: leadId })
    return () => unregisterTaskTarget(key)
  }, [])

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const lead = keysToCamel({
    ...data.lead,
    photos: [...data.lead.photos].sort((a, b) => {
      if (b.timestamp < a.timestamp) {
        return -1
      }
      if (b.timestamp > a.timestamp) {
        return 1
      }
      return 0
    }),
  })

  const updateContextNote = ({ payload, successCallback }) => {
    setWaiting(true)

    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_lead_attributes`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        lead_id: lead.id,
        updated_attributes: {
          context_notes: payload.contextNotes,
        },
      }),
    })
      .then(res => res.ok || window.alert('Error'))
      .finally(() => {
        setWaiting(false)
        successCallback()
      })
  }

  const handleSubmitCustomerDeclined = reason => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/customer_declined`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          leadId: lead.id,
          reason: customerDeclinedReason.trim(),
        }),
      })
      .then(res => (res.ok ? setShowCustomerDeclinedModal(false) : window.alert('Error')))
      .then(() => refetch())
  }

  const handleCheckCustomerDeclined = () => setShowCustomerDeclinedModal(true)

  const toggleCheckPropertyManagerApproved = e =>
    window.confirm(`Confirm you want to ${e.target.checked ? '' : 'un'}check this`) &&
    window
      .fetch(
        `${process.env.REACT_APP_COMMAND_ROOT}/toggle_property_manager_approved_${e.target.checked ? 'on' : 'off'}`,
        {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            userId: user.id,
            leadId: lead.id,
          }),
        }
      )
      .then(res => res.ok || window.alert('Error'))

  const toggleCheckWaitingForCustomer = e =>
    window.confirm(`Confirm you want to ${e.target.checked ? '' : 'un'}check this`) &&
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_waiting_for_customer_${e.target.checked ? 'on' : 'off'}`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          leadId: lead.id,
        }),
      })
      .then(res => res.ok || window.alert('Error'))

  const handleUncheckCustomerDeclined = () =>
    window.confirm('Confirm you want to uncheck this') &&
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/reverse_customer_declined`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          leadId: lead.id,
        }),
      })
      .then(res => res.ok || window.alert('Error'))

  const handleToggleBooleanAttribute = ({ attribute, event }) => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_lead_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          userId: user.id,
          leadId: lead.id,
          updatedAttributes: {
            [attribute]: event.target.checked,
          },
        }),
      })
      .then(res => res.ok || window.alert('Error'))
  }

  const handleToggleMarkedGravy = () => {
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_gravy_lead_${lead.markedGravyAt ? 'off' : 'on'}`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        userId: user.id,
        leadId: lead.id,
      }),
    }).then(res => res.ok || window.alert('Error'))
  }

  const handleUpdateEmojis = newEmojis => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_lead_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          lead_id: lead.id,
          updated_attributes: {
            emojis: newEmojis,
          },
        }),
      })
      .then(res => {
        if (res.ok) {
          refetch()
          // setEmojis([])
        } else {
          window.alert('Error')
        }
      })
  }

  const handleRemoveEmojis = () => {
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/update_lead_attributes`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          lead_id: lead.id,
          updated_attributes: {
            emojis: [],
          },
        }),
      })
      .then(res => {
        if (res.ok) {
          refetch()
          setEmojis([])
        } else {
          window.alert('Error')
        }
      })
  }

  const soldByUser = lead.soldByUserId && global.users.find(user => user.id === lead.soldByUserId)

  const handleToggleDeleted = e => {
    if (e.target.checked) {
      window.confirm('Delete (hide) this?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/delete_lead`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            userId: user.id,
            leadId: lead.id,
          }),
        }).then(res => (res.ok ? refetch() : window.alert('Error')))
    } else {
      window.confirm('Restore (reverse delete) this?') &&
        fetch(`${process.env.REACT_APP_COMMAND_ROOT}/reverse_delete_lead`, {
          method: 'POST',
          headers: bearerTokenHeaders,
          body: JSON.stringify({
            userId: user.id,
            leadId: lead.id,
          }),
        }).then(res => (res.ok ? refetch() : window.alert('Error')))
    }
  }
  const handleClickRequestMorePhotos = () => {
    setDraftedMorePicsOnClose(true)
    window
      .fetch(`${process.env.REACT_APP_COMMAND_ROOT}/request_more_photos`, {
        method: 'POST',
        headers: bearerTokenHeaders,
        body: JSON.stringify({
          user_id: user.id,
          lead_id: lead.id,
        }),
      })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        window.alert('check close in a few seconds')
      })
      .catch(err => {
        window.alert(err)
      })
  }

  const handleToggleAluminumVehicle = () =>
    window.confirm('are you sure') &&
    fetch(`${process.env.REACT_APP_COMMAND_ROOT}/toggle_vehicle_is_aluminum`, {
      method: 'POST',
      headers: bearerTokenHeaders,
      body: JSON.stringify({
        user_id: user.id,
        lead_id: lead.id,
      }),
    })
      .then(response => {
        if (response.ok) {
          return true
        } else {
          throw Error('error')
        }
      })
      .then(data => {
        refetch()
      })
      .catch(err => {
        window.alert(err)
      })

  const fontStyle = lead?.contextNotes ? {} : { fontStyle: 'italic' }

  return (
    <>
      <Grid container spacing={0} className={lead.deletedAt ? classes.wrapDeleted : classes.wrapNormal}>
        <Grid item xs={8}>
          <Grid container spacing={0}>
            {lead.originalLead && (
              <Grid
                item
                xs={12}
                sx={{
                  background: 'green',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  '& a': {
                    color: 'white',
                    fontWeight: 'bold',
                  },
                }}
              >
                this lead was duplicated from{' '}
                <Link to={`/leads/${lead?.originalLead?.id}`}> {lead?.originalLead?.name}&lsquo;s lead </Link>
              </Grid>
            )}

            <Grid item xs={12} sx={{ display: 'flex' }}>
              {lead?.year && lead?.make && lead?.model && (
                <Box sx={{ ml: '1rem', '& svg': { fontSize: '12px' }, fontSize: '12px' }}>
                  {lead?.vehicleIsAluminum && <StorageOutlined />} {lead?.vehicleIsAluminum && <>Saved as aluminum </>}
                  <button onClick={() => handleToggleAluminumVehicle()}>
                    {lead?.vehicleIsAluminum
                      ? `Click to remove ${lead?.year} ${lead?.make} ${lead?.model} from aluminum database`
                      : 'Mark as aluminum'}
                  </button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Link to={`/customers/${lead.customer.id}`}>
                <Typography display='inline'>[C]</Typography>
              </Link>
              &nbsp;&nbsp;
              <Grid className={emojiClasses.emojiSelector}>
                {!emojis?.length && (
                  <Button sx={{ minWidth: '5px' }} onClick={() => setShowEmojis(!showEmojis)}>
                    <img
                      className='add-emoji-img'
                      src='https://static-00.iconduck.com/assets.00/emoji-add-icon-512x512-z7wwtx1x.png'
                      alt='add emoji button'
                    />
                  </Button>
                )}

                <Dialog open={showEmojis} onClose={handlePickerClose}>
                  <Box sx={{ display: 'flex', padding: '1em' }}>
                    {emojis?.length !== 0 &&
                      emojis?.map((emoji, index) => {
                        const onEmojiRemove = () => {
                          const baseEmojis = [...emojis]
                          baseEmojis.splice(index, 1)
                          setEmojis(baseEmojis)
                        }

                        return (
                          <Box sx={{ padding: '0 0.5em', cursor: 'pointer' }} key={emoji} onClick={onEmojiRemove}>
                            <em-emoji set={'apple'} size='2em' key={index} shortcodes={emoji}></em-emoji>
                          </Box>
                        )
                      })}
                  </Box>
                  <Picker
                    data={appleData}
                    set='apple'
                    onEmojiSelect={emoji => {
                      if (emoji?.shortcodes) setEmojis([...emojis, emoji.shortcodes])
                    }}
                  />
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => setEmojis([])}>Remove All</Button>
                  </Box>
                </Dialog>

                {emojis?.length > 0 && <Button onClick={() => handleRemoveEmojis()}>Remove Emojis</Button>}
              </Grid>
              <Box
                sx={{ display: 'inline-block', cursor: 'pointer', paddingRight: '5px' }}
                onClick={() => setShowEmojis(!showEmojis)}
              >
                {emojis?.map((emoji, index) => (
                  <Box key={emoji} sx={{ padding: '0 2px', display: 'inline-block' }}>
                    <em-emoji set={'apple'} size='1.2em' key={index} shortcodes={emoji}></em-emoji>
                  </Box>
                ))}
              </Box>
              <Link to={`/leads/${lead.id}`}>
                <Typography display='inline'>
                  {/* {emojis?.length === 0 && (
                  <Box sx={{display: "inline-flex", paddingRight: '5px'}}>
                   {lead.emojis?.map((emoji,index) => (
                      <Box key={emoji} sx={{padding: '0 2px'}}>
                         <em-emoji size="1.2em" key={index} shortcodes={emoji}></em-emoji>
                      </Box>
                    ))}
                  </Box>
                )} */}

                  <strong style={{ fontSize: '1.2em' }}>{lead.name} </strong>
                </Typography>
              </Link>
              &nbsp;&nbsp;
              <Link cy-data='edit-lead-link' to={`/leads/${leadId}/edit`}>
                [edit]
              </Link>
              <Box sx={{ marginLeft: '1em', display: 'inline' }}>
                {DateTime.fromISO(lead.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
                &nbsp;&nbsp;
                {!lead.deletedAt && <CreateATask targetType='Lead' targetId={lead.id} />}
                {lead.deletedAt && (
                  <>
                    <b>Deleted</b>: {DateTime.fromISO(lead.deletedAt).toLocaleString(DateTime.DATETIME_SHORT)}
                  </>
                )}
                <DuplicateLead lead={lead} refetch={refetch} />
                &nbsp;&nbsp;
                <FormControlLabel
                  cy-data='delete-lead-switch'
                  value='delete'
                  control={<MuiSwitch color='primary' checked={!!lead.deletedAt} onChange={handleToggleDeleted} />}
                  label='Delete this lead'
                  labelPlacement='right'
                />
              </Box>
              <Box>
                <LeadSiblingWarning lead={lead} refetch={refetch} />
                <LeadDuplicatesWarning lead={lead} refetch={refetch} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.leadAttr}>
                {(lead.year && lead.make && lead.model && (
                  <strong>
                    {lead.year} - {lead.make} - {lead.model}
                  </strong>
                )) ||
                  (lead.year && lead.vehicle?.makePrettyName && lead.vehicle?.modelPrettyName && (
                    <span>{`${lead.year} - ${lead.vehicle.makePrettyName} - ${lead.vehicle.modelPrettyName}`}</span>
                  ))}

                {lead.omegaVehicleId && <OmegaVehicleInfo omegaVehicleId={lead.omegaVehicleId} />}
              </Box>

              {lead.vin && (
                <Box className={classes.leadAttr}>
                  <b> VIN: </b>
                  {lead.vin}
                </Box>
              )}

              <Box className={classes.leadAttr}>
                <b> repair location: </b>
                {lead.repairLocation}
                <RepairLocationIcon repairLocation={lead.repairLocation} />
              </Box>

              <Box className={classes.leadAttr}>
                <b>source: </b>
                {lead.source}
              </Box>

              <LabelReadWriter
                obj={lead}
                idName='leadId'
                freeSolo={false}
                allowNull={false}
                propName='serviceType'
                options={['autobody', 'tires', 'windshields']}
                endpoint='update_lead_attributes'
                refetch={refetch}
              />

              <Box className={classes.leadAttr}>
                <b>email: </b>
                {lead.email}
              </Box>

              <Box className={classes.leadAttr}>
                <b>phone: </b>
                {lead.phone}
              </Box>
            </Grid>

            <Grid item xs={12}>
              {(lead.addressLineOne && (
                <Box className={classes.leadAttr}>
                  <b>address: </b>
                  {lead.addressLineOne}
                  {lead.addressVerifiedAt ? (
                    <Chip size='small' className='verified' label='Verified' icon={<VerifiedUserRounded />} />
                  ) : (
                    <Chip size='small' className='unverified' label='not verified' icon={<GppBadRounded />} />
                  )}
                </Box>
              )) || (
                <Box className={classes.leadAttr}>
                  <b>NO ADDRESS YET</b>
                </Box>
              )}
              {lead.addressLineTwo && (
                <Box className={classes.leadAttr}>
                  <b>address line 2: </b>
                  {lead.addressLineTwo}
                </Box>
              )}
              <Box className={classes.leadAttr}>
                <b>city: </b>
                {lead.city}
              </Box>
              <Box className={classes.leadAttr}>
                <b>state: </b>
                {lead.state}
              </Box>
              <Box className={classes.leadAttr}>
                <b>zip: </b>
                {lead.zip}
              </Box>
              <Box className={classes.leadAttr}>
                <b>market: </b>
                {lead.market}
              </Box>
              <Box className={classes.leadAttr}>
                <LabelReadWriter
                  obj={lead}
                  idName='leadId'
                  options={leadLabels}
                  endpoint='update_lead_attributes'
                  refetch={refetch}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.leadAttr}>
                <b>interaction statuses: </b>
                {lead.interactionStatuses?.map(i => (
                  <Chip size='small' key={i} label={i} />
                ))}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.noWay}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'noWay', event })}
                    color='secondary'
                    title='NO WAY JOSE!'
                  />
                }
                label='NO WAY'
                title='NO WAY JOSE!'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.carparts}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'carparts', event })}
                    color='secondary'
                    title='CARPARTS'
                  />
                }
                label='CARPARTS'
                title='carparts lead'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.garageConfirmed}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'garageConfirmed', event })}
                    color='secondary'
                    title='Garage confirmed?'
                  />
                }
                label='Garage Confirmed?'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={!!lead.markedGravyAt}
                    onChange={handleToggleMarkedGravy}
                    color='secondary'
                    title='Gravy'
                  />
                }
                label='Gravy'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.isATest}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'isATest', event })}
                    color='secondary'
                    title='TEST LEAD'
                  />
                }
                label='TEST LEAD'
              />

              {(lead.customerDeclinedAt && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        checked={lead.customerDeclinedAt}
                        onChange={event => handleUncheckCustomerDeclined()}
                        color='secondary'
                        title={lead.customerDeclinedReason}
                      />
                    }
                    label='Customer Declined'
                  />
                  <Chip size='small' color='error' label={lead.customerDeclinedReason} sx={{ marginRight: '1em' }} />
                </>
              )) || (
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={lead.customerDeclinedAt}
                      onChange={event => handleCheckCustomerDeclined()}
                      color='secondary'
                      title='Customer Declined'
                    />
                  }
                  label='Customer Declined'
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.phoneLead}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'phoneLead', event })}
                    color='secondary'
                    title='Phone Lead'
                  />
                }
                label='Phone Lead'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.hot}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'hot', event })}
                    color='secondary'
                    title='A "Check on Hot Lead" Task is generated every 48 hours for every Hot lead'
                  />
                }
                label='Hot'
              />
              {lead.repairLocation !== 'house' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={!!lead.propertyManagerApprovedAt}
                      onChange={toggleCheckPropertyManagerApproved}
                      color='secondary'
                      title='Property manager approval has been granted'
                    />
                  }
                  label='Property manager approval?'
                />
              )}
              {!lead.soldAt && (
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={!!lead.markedWaitingForCustomerAt}
                      onChange={toggleCheckWaitingForCustomer}
                      color='secondary'
                      title='Await customer ok; prevent Smart Followups'
                    />
                  }
                  label='Waiting for Customer'
                />
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.useTechnicianDriveway}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'use_technician_driveway', event })}
                    color='secondary'
                    title='Use Technician Driveway'
                  />
                }
                label='Use Technician Driveway'
                title='use technician driveway'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    checked={lead.serviceUpReferral}
                    onChange={event => handleToggleBooleanAttribute({ attribute: 'service_up_referral', event })}
                    color='secondary'
                    title='This lead is reserved for service up'
                  />
                }
                label='Service up referral'
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <b>Description:</b>
              </Typography>

              <Card variant='outlined'>
                <CardContent style={{ padding: '0.5em', overflowWrap: 'anywhere' }}>{lead.description}</CardContent>
              </Card>
            </Grid>

            {!lead.customer.noClose && (
              <>
                <Grid item xs={12}>
                  <a target='_blank' href={`https://app.close.com/search/${lead.id}/`} rel='noreferrer'>
                    Show this lead in close
                  </a>
                </Grid>

                <Grid item xs={12}>
                  <a target='_blank' href={`https://app.close.com/search/${lead.email}/`} rel='noreferrer'>
                    Search close by email
                  </a>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color='primary'
                    size='small'
                    disabled={draftedMorePicsOnClose}
                    onClick={handleClickRequestMorePhotos}
                  >
                    request more photos (close drafts)
                  </Button>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {soldByUser &&
                `Initially sold by ${soldByUser.firstName} ${soldByUser.lastName},
              on ${DateTime.fromISO(lead.soldAt, { zone: 'America/Los_Angeles' }).toISODate()}`}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} mt={2}>
              <Typography>
                <b>Context Notes:</b>
              </Typography>

              {!showEditContextNote ? (
                <Box sx={{ ...fontStyle }}>
                  {lead.contextNotes ? lead.contextNotes : 'No notes to display '} -{' '}
                  <span onClick={() => setShowEditContextNote(true)} style={{ color: 'gray', cursor: 'pointer' }}>
                    [edit]
                  </span>{' '}
                </Box>
              ) : (
                <>
                  <br />
                  <TextField
                    size='small'
                    value={editableLead?.contextNotes || ''}
                    onChange={event => setEditableLead({ ...lead, contextNotes: event.target.value })}
                  />
                  <Button
                    onClick={() => {
                      updateContextNote({
                        payload: {
                          contextNotes: editableLead?.contextNotes,
                        },
                        successCallback: () => setShowEditContextNote(false),
                      })
                    }}
                    disabled={waiting || lead?.contextNotes === editableLead?.contextNotes}
                  >
                    save
                  </Button>
                  <Button onClick={() => setShowEditContextNote(false)}>cancel</Button>
                </>
              )}
            </Grid>
          </Grid>

          {(lead?.serviceType === 'autoglass' || lead?.serviceType === 'windshields') &&
            lead?.selectedGlassLocations?.length > 0 && (
              <Grid container>
                <Grid item xs={12} mt={2}>
                  <Typography>
                    <b>Glass Locations:</b>
                  </Typography>
                  {lead?.selectedGlassLocations?.map(loc => (
                    <Chip size='small' key={loc} label={loc} />
                  ))}
                </Grid>
              </Grid>
            )}
        </Grid>

        <Grid item xs={12} sx={{ p: 1 }}>
          <AvailabilityMap lead={lead} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Switch>
            <Route path={`${path}/quotes`}>
              <QuotesRouter lead={lead} />
            </Route>
            <Route path={`${path}/edit`}>
              <Edit lead={lead} />
            </Route>
            <Route path={path}>
              <Show lead={lead} refetchLead={refetch} />
            </Route>
          </Switch>
        </Grid>
      </Grid>

      <Snackbar open={successSnackbarIsOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarIsOpen(false)}>
        <MuiAlert severity='success'>{successMessage}</MuiAlert>
      </Snackbar>

      <Modal open={showCustomerDeclinedModal} onClose={() => setShowCustomerDeclinedModal(false)}>
        <Box sx={modalStyle}>
          <Typography variant='h6' component='h2'>
            Customer Declined Reasons
          </Typography>

          <Typography sx={{ mt: 2 }}>
            <Box sx={{ '& .MuiTextField-root': { m: 1, width: '50ch' } }}>
              <FormControl fullWidth>
                <InputLabel id='customer-declined-reason-select'>Declined Reason</InputLabel>
                <Select
                  value={customerDeclinedReason}
                  label='Declined Reason'
                  placeholder='Declined Reason'
                  labelId='customer-declined-reason-select'
                  onChange={e => setCustomerDeclinedReason(e.target.value)}
                >
                  <MenuItem value='Already fixed'>Already fixed</MenuItem>
                  <MenuItem value='Found cheaper price'>Found cheaper price</MenuItem>
                  <MenuItem value='No reason'>No reason</MenuItem>
                  <MenuItem value='Didn’t want to wait'>Didn’t want to wait</MenuItem>
                  <MenuItem value='Ghosted'>Ghosted</MenuItem>
                  <MenuItem value='Going somewhere else'>Going somewhere else</MenuItem>
                  <MenuItem value='Low budget'>Low budget</MenuItem>
                  <MenuItem value='No location'>No location</MenuItem>
                  <MenuItem value='Service we don’t offer'>Service we don&apos;t offer</MenuItem>
                  <MenuItem value='Stop'>Stop</MenuItem>
                  <MenuItem value='Too expensive'>Too expensive</MenuItem>
                  <MenuItem value='Will not fix'>Will not fix</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <Button
                onClick={handleSubmitCustomerDeclined}
                disabled={customerDeclinedReason.length < 4}
                sx={{ marginTop: '1em' }}
                variant='outlined'
              >
                Submit
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

const RepairLocationIcon = ({ repairLocation }) => {
  switch (repairLocation) {
    case 'house':
      return <HomeIcon />
    case 'apartment':
      return <ApartmentIcon />
    case 'office':
      return <BusinessIcon />
    case 'help':
      return <ContactSupportIcon />
    default:
      return null
  }
}

const CUSTOMER_QUERY = gql`
  query customer($leadId: ID!) {
    customer(leadId: $leadId) {
      id
      firstName
      lastName
      phone
      noClose
    }
  }
`

const CustomerFetcher = () => {
  const { leadId } = useParams()

  const { loading, error, data } = useQuery(CUSTOMER_QUERY, {
    variables: { leadId: leadId },
  })

  if (loading) return <div>LOADING...</div>
  if (error) return <div>Error!</div>

  const customer = keysToCamel(data.customer)

  return <LeadRoot customer={customer} />
}

export default CustomerFetcher
